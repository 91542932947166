export const COMMISSION_RULE_TYPE = [
  {
    label: 'Fixed rate for all products',
    value: 'NEW_COMMISSION'
  },
  {
    label: 'Modify base commission by %',
    value: 'RECALCULATE'
  },
]

export const COMMISSION_RULE_TYPE_MAP = {
  'RECALCULATE': 'Modify base commission by %',
  'NEW_COMMISSION': 'Fixed rate for all products'
}