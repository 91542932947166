<template>
  <div class="rule-home">
    <a-spin :spinning="spinning">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-a-shezhi2"></use>
        </svg>
      </breadcrumbs>
      <div class="content">
        <div class="rule-wrap">
          <pulic-report-notice v-if="showNotice" :class="'rule'" type="urgent" @click="closeNotice" >
            <template #title>You are able to set an exclusive commission rate for the specific publisher herein. After the setting is completed, for this publisher, both the display and calculation of commission rates for all products will be based on this set rate.</template>
            <template #close></template>
          </pulic-report-notice>
          <div class="btn">
            <artmisads-button type="primary" size="middle" @click="() => setShowModal(true)">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-jiahao"></use>
              </svg>
              Add New Rule
            </artmisads-button>
          </div>
          <ruleTemp
            :list="list"
            :getData="getData"
            :defaultCommission="defaultCommission"
          />
          <div class="rule-pagination" v-if="listTotal > 0">
            <artemisads-pagination
              :change="onChangePage"
              :pagination="{
                total: listTotal,
                current: params.page,
                pageSize: params.size,
                showSizeChanger: true
              }"
            />
          </div>
        </div>
      </div>
      <editRuleModal
        :modalData="{}"
        :setShowModal="setShowModal"
        :defaultCommission="defaultCommission"
        v-if="showModal"
      />
    </a-spin>
  </div>
</template>
<script setup>
  import { keyBy } from 'lodash';
  import { ref, onMounted, watch, onBeforeMount } from "vue";
  import { connectionApi } from "@/server/seller/index";
  import { productsApi } from '@/server/index'
  import editRuleModal from './editRuleModal.vue';
  import breadcrumbs from "../../breadcrumbs.vue";
  import ruleTemp from './ruleTemp.vue';
  import PulicReportNotice from "@/components/PulicReportNotice.vue";

  const breadcrumbsData = {
    title: "Commission Rule",
    color: "#776D91",
    links: [
      {
        name: "Home",
        path: "/seller/dashboard",
      },
      {
        name: "Settings",
        path: null,
      },
      {
        name: "Commission Rule",
        path: "/seller/commission-Rule",
      },
    ],
  };
  const showNotice = ref(false);
  const showModal = ref(false);
  const defaultCommission = ref({
    defaultCommissionRate: 1,
    platformFeeFactor: 1,
    platformMaxFeeRate: 1,
  })
  const params = ref({
    page: 1,
    size: 10
  })
  const listTotal = ref(0);
  const list = ref([]);
  const spinning = ref(false);

  const onChangePage = (data) => {
    const { current, pageSize } = data;
    params.value.page = current;
    params.value.size = pageSize;
  }

  watch(params.value, () => {
    getData();
  })

  const getData = async () => {
    spinning.value = true;
    const res = await connectionApi.getSellerSettingsCommissionRuleList(params.value);
    const { success, data } = res;
    if (success) {
      list.value = data.data;
      listTotal.value = data.total;
    }
    spinning.value = false;
  };

  const setShowModal = (status, reload) => {
    showModal.value = status;
    if (reload) {
      getData()
    }
  }

  const closeNotice = () => {
    localStorage.setItem('SELLER_COMMISSION_RULE_SHOW_NOTICE', '1');
    showNotice.value = false;
  }

  onBeforeMount(() => {
    showNotice.value = localStorage.getItem('SELLER_COMMISSION_RULE_SHOW_NOTICE') !== '1';
  })

  const getRate = async () => {
    const res = await productsApi.getCommissionConfig()
    const { success, data } = res
    if (success) {
      defaultCommission.value = data
    }
  }

  onMounted(() => {
    getData();
    getRate();
  })

</script>
<style lang="less" scoped>
  .rule-home {
    padding: 0 16px;
    .content {
      display: flex;
      .rule-wrap {
        width: 100%;
      }
    }
    .btn {
      button {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          align-self: center;
          margin-right: 8px;
        }
      }
    }
    .rule-pagination {
      :deep(.ant-pagination) {
        padding: 16px 16px 16px 16px;
        background-color: var(--color-white);
        box-shadow: var(--section-box-shadow-5);
        border-radius: 8px;
      }
      .pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 0;
      }
    }
  }
</style>
