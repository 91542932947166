<template>
  <artmisads-modal
    :open="true"
    :title="props.modalData.id ? 'Edit Rule' : 'Add New Rule'"
    :width="720"
    :onCancel="() =>  props.setShowModal(false)"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="ruleModal">
        <div class="item">
          <a-form>
            <a-form-item
              name="ruleName"
              v-bind="validateInfos.ruleName"
            >
              <div class="input-item">
                <span class="text">Rule Name<span>*</span></span>
                <artmisads-input :disabled="!!props.modalData.id" maxlength="100" style="width: 560px;" @blur="() => validate('ruleName')" size="middle" v-model:value="modelRef.ruleName"/>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="commissionType"
              v-bind="validateInfos.commissionType"
            >
              <div class="input-item" ref="specialCommissionRef">
                <span class="text">
                  Rule Rate Type<span>*</span>
                  <a-popover
                    :getPopupContainer="() => specialCommissionRef"
                  >
                    <template #content>
                      <p class="tooltip-text">
                        You can choose one of the following two methods to set the exclusive commission rate:<br/>
                        1. Fixed rate for all products：Reset the new commission rate for the designated Publisher according to the product Sales for all products.<br/>
                        2. Modify base commission by %Recalculate based on the already set product commission rate.
                      </p>
                    </template>
                    <svg class="wenhao-icon icon" aria-hidden="true">
                      <use xlink:href="#icon-wenhao-tianchong"></use>
                    </svg>
                  </a-popover>
                </span>
                <artmisads-select
                  v-model:value="modelRef.commissionType"
                  style="width: 560px;"
                  size="middle"
                  :options="COMMISSION_RULE_TYPE"
                  @blur="() => validate('commissionType')"
                  placeholder="Select..."
                />
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="item" v-if="modelRef.commissionType === 'RECALCULATE'">
          <a-form>
            <a-form-item
              name="commissionRate"
              v-bind="validateInfos.commissionRate"
            >
            <div class="input-item">
              <span class="text">Publishers Commission</span>
              <artmisads-input-number
                :min="0"
                :max="999"
                suffix="%"
                :step="1"
                size="large"
                 @blur="e => commissionBlur(e, 999)"
                v-model:value="modelRef.commissionRate"
                @change="handleChangeCommission($event)"
                placeholder="Please enter(1% - 999%)"
              />
            </div>
            </a-form-item>
          </a-form>
          <p class="note">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-info"></use>
            </svg>
            (E.g. Saving it as 120% means that you will pay 20% more in commission to the designated publisher.)
          </p>
        </div>
        <div class="item-commission" v-else-if="modelRef.commissionType === 'NEW_COMMISSION'">
          <div class="commission-input">
            <span>Publisher Commission</span>
            <artmisads-input-number
              @blur="e => commissionBlur(e, 99)"
              :min="0"
              :max="100"
              suffix="%"
              :step="0.5"
              size="large"
              placeholder="Please enter..."
              v-model:value="modelRef.commissionRate"
              @change="handleChangeCommission($event)"
            />
          </div>
          <span>+</span>
          <div class="fees">
            <span
              >ArtemisAds Fees<a-tooltip
                :getPopupContainer="() => $refs.setCommissionRef"
              >
                <template #title
                  >Publisher Commission x 20%, Max 3.5%</template
                >
                <Icon
                  name="wenhao-tianchong"
                  class="question-icon"
                /> </a-tooltip
            ></span>
            <artmisads-button disabled
              >{{ fees.toFixed(1) }}%</artmisads-button
            >
          </div>
          <span>=</span>
          <div class="total">
            <span>Total</span>
            <artmisads-button disabled>
              <span>{{
                modelRef.commissionRate
                  ? parseFloat(Number(fees) + Number(modelRef.commissionRate)).toFixed(1)
                  : Number(fees).toFixed(1)
              }}</span>
              <span>%</span>
            </artmisads-button>
          </div>
        </div>
        <div class="item">
          <a-form>
            <a-form-item
              name="publisherVOList"
              v-bind="validateInfos.publisherVOList"
            >
              <div class="input-item">
                <span class="text">Publishers<span>*</span></span>
                <artmisads-select
                  show-search
                  mode="multiple"
                  v-model:value="modelRef.publisherVOList"
                  style="width: 560px"
                  :options="publisherOptions"
                  @search="searchPublisher"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  allowClear
                  @change="onChangePublisher"
                  @blur="() => validate('publisherVOList')"
                />
                <!-- <artmisads-select size="large" style="width: 560px;" v-model:value="modelRef.publisherVOList" mode="multiple" :options="categories"/> -->
              </div>
            </a-form-item>
          </a-form>
          <p class="note">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-info"></use>
            </svg>
            You can add up to 10 publishers by searching for their names.
          </p>
        </div>
        <div class="item">
          <div class="input-item">
            <span class="text">Date Range<span>*</span></span>
            <div class="date-item">
              <a-form>
                <a-form-item
                  name="startDate"
                  v-bind="validateInfos.startDate"
                >
                  <artemisads-date-picker
                    :disabled="!!props.modalData.id"
                    style="width: 270px"
                    v-model:value="modelRef.startDate"
                    :allowClear="false"
                    :disabled-date="disabledStartDate"
                    @change="onChangeStartDate"
                    @blur="() => validate('startDate')"
                  />
                </a-form-item>
              </a-form>
              <a-form>
                <a-form-item
                  name="endDate"
                  v-bind="validateInfos.endDate"
                >
                  <artemisads-date-picker
                    v-model:value="modelRef.endDate"
                    disabledEndDate
                    :allowClear="false"
                    :disabled-date="disabledEndDate"
                    @blur="() => validate('endDate')"
                    style="width: 270px"
                  />
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="ruleModal-btn">
        <span class="operation-btn">
          <a-popconfirm
            title="Are you sure to stop this rule?"
            ok-text="Confirm"
            cancel-text="Cancel"
            @confirm="onExpire"
            :overlayInnerStyle="{padding: '16px'}"
          >
            <span class="to-expire" v-if="props.modalData.status === 'Active'">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-error3"></use>
              </svg>
              Expire
            </span>
          </a-popconfirm>
          <a-popconfirm
            title="Are you sure to delete this rule?"
            ok-text="Confirm"
            cancel-text="Cancel"
            @confirm="onDelete"
            :overlayInnerStyle="{padding: '16px'}"
          >
            <span class="to-delete" v-if="props.modalData.status === 'Scheduled'">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-lajitong"></use>
              </svg>
              Delete
            </span>
          </a-popconfirm>
        </span>
        <div class="ok-cancel-btn">
          <artmisads-button @click="() => setShowModal()">Cancel</artmisads-button>
          <artmisads-button type="primary" @click="sellerSettingsCommissionRuleUpdate">Save</artmisads-button>
        </div>
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import moment from 'moment';
import { Form, message } from 'ant-design-vue';
import { ref, reactive, onMounted, computed } from "vue";
import { connectionApi } from "@/server/seller/index";
import { COMMISSION_RULE_TYPE } from '~/contants/setting'

const props = defineProps(["modalData", 'setShowModal', 'countryList', 'defaultCommission']);

const specialCommissionRef = ref();
const spinning = ref(false);
const publisherOptions = ref([]);
const useForm = Form.useForm;
  const modelRef = reactive({
    ruleName: props.modalData.ruleName || '',
    commissionType: props.modalData.commissionType || null,
    publisherVOList: props.modalData.publisherVOList ? props.modalData.publisherVOList.map(item => {
      item.value = item.id;
      item.label = item.creatorName;
      return item;
    }) : [],
    commissionRate: props.modalData.commissionRate || '',
    startDate: props.modalData.startDate ? moment(props.modalData.startDate) : moment().add(1, 'day'),
    endDate: props.modalData.startDate ?  moment(props.modalData.endDate): moment().add(31, 'day')
  });

  const rulesRef = reactive({
    ruleName:  [{
      required: true,
      message: 'Please fill in your Rule Name',
      trigger: ['blur']
    }],
    publisherVOList: [{
      required: true,
      message: 'Please fill in your publishers list',
      trigger: ['blur']
    }],
    commissionType: [{
      required: true,
      message: 'Please fill in your commission type',
      trigger: ['blur']
    }],
    startDate: [{
      required: true,
      message: 'Please fill in your start date',
      trigger: ['blur']
    }],
    endDate: [{
      required: true,
      message: 'Please fill in your end date',
      trigger: ['blur']
    }],
    commissionRate: [{
      required: true,
      message: 'Please fill in your commission rate',
      trigger: ['blur']
    }],
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

const disabledStartDate = current => {
  return current && current < moment().add(1, 'day');
}

const disabledEndDate = current => {
  return current && (current < modelRef.startDate || moment().add(1, 'day') > current);
}


const commissionBlur = (e, max) => {
  validate('commissionRate');
  const newValue = e.target.value
    .replace(/[^0-9.]/g, '')
    .split('.')
    .map((v, i) => {
      v = v ? parseInt(v) : null
      if (i && v) {
        v = 5
      }
      return v
    })
    .join('.')
  let data = Number(newValue);
  data = data > max ? max : data;
  if (max === 999) {
    data = Math.floor(data);
  }
  modelRef.commissionRate = data;
}

const onChangeStartDate = data => {
  modelRef.endDate = moment(data.valueOf()).add(1, 'day');
}

const fees = computed(() => {
  let value = 0
  if (modelRef.commissionRate) {
    value = (modelRef.commissionRate * props.defaultCommission.platformFeeFactor) / 100
  } else {
    value =
      (props.defaultCommission.defaultCommissionRate *
      props.defaultCommission.platformFeeFactor) /
      100
    if (modelRef.commissionRate == 0) {
      value =
        (modelRef.commissionRate * props.defaultCommission.platformFeeFactor) / 100
    }
  }
  if (value >= props.defaultCommission.platformMaxFeeRate) {
    value = props.defaultCommission.platformMaxFeeRate
  }
  let arr = value && value.toString().split('.')
  let firstNumber = arr && Number(arr[0])
  let middleNum = '0'
  if (arr && arr.length > 1) {
    middleNum = Number(arr[1][0])
    if (arr[1][1] == 5) {
      if (arr[1][0] % 2 !== 0) {
        middleNum += 1
        if (arr[1][0] == 9) {
          middleNum = 0
          firstNumber += 1
        }
      }
    } else if (arr[1][1] > 5) {
      middleNum += 1
      if (arr[1][0] == 9) {
        middleNum = 0
        firstNumber += 1
      }
    }
  }
  let endNum = arr && parseFloat(firstNumber + '.' + middleNum)
  return endNum
})

const sellerSettingsCommissionRuleUpdate = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let paramsData = validateRes, res;
    paramsData.startDate = paramsData.startDate.format('YYYY-MM-DD');
    paramsData.endDate = paramsData.endDate.format('YYYY-MM-DD');
    paramsData.publisherIds = paramsData.publisherVOList.map(item => item.value || item);
    delete paramsData.publisherVOList
    if (props.modalData.id) {
      paramsData.id = props.modalData.id;
      res = await await connectionApi.sellerSettingsCommissionRuleUpdate(paramsData);
    } else {
      res = await await connectionApi.sellerSettingsCommissionRuleCreate(paramsData);
    }
    const { success, code } = res;
    if (success) {
      props.setShowModal(false, true);
    } else {
      if (code === '1400') {
        message.warning('The rule name already exists, please choose a new one.')
      }
    }
    spinning.value = false;
  }
};

const handleChangeCommission = (e) => {
  modelRef.commissionRate = e;
}

const onChangePublisher = data => {
  modelRef.publisherVOList = data.splice(0, 10);
}

const onDelete = async () => {
  spinning.value = true;
  let paramsData = {id: props.modalData.id};
  const res = await await connectionApi.sellerSettingsCommissionRuleDelete(paramsData);
  const { success } = res;
  if (success) {
    props.setShowModal(false, true);
  }
  spinning.value = false;
}

const onExpire = async () => {
  spinning.value = true;
  let paramsData = {id: props.modalData.id};
  const res = await await connectionApi.sellerSettingsCommissionRuleExpire(paramsData);
  const { success } = res;
  if (success) {
    props.setShowModal(false, true);
  }
  spinning.value = false;
}

const searchPublisher = async name => {
  let res, paramsData = {
    name,
    ruId: props.modalData.id || '',
    endDate: modelRef.endDate.format('YYYY-MM-DD'),
    startDate: modelRef.startDate.format('YYYY-MM-DD'),
  };
  res = await connectionApi.getSellerSettingsCommissionRuleSearchPublisher(paramsData);
  if (res && res.data) {
    publisherOptions.value = res.data.map(item => {
      item.value = item.id;
      item.label = item.creatorName;
      return item;
    })
  }
}

</script>

<style lang="less" scoped>
.ruleModal {
  padding-bottom: 3px;
  .item {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    &:first-child {
      margin-top: 8px;
    }
    .date-item {
      display: flex;
      justify-content: space-between;
    }
    .note {
      display: flex;
      height: 18px;
      line-height: 18px;
      color: var(--dark-3);
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.3px;
      border-radius: 8px;
      margin-top: 6px;
      svg {
        width: 16px;
        height: 16px;
        fill: var(--dark-3);
        margin-right: 4px;
        align-self: center;
      }
    }
    .wenhao-icon {
      width: 16px;
      height: 16px;
      margin-left: 2px;
      fill: var(--bg-gray-7);
      &:focus {
        outline: none;
      }
    }
    :deep(.ant-form) {
      .input-item {
        display: flex;
        flex-direction: column;
        width: 560px;
        .text {
          color: var(--dark-2);
          font-weight: 500;
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 4px;
          display: flex;
          > span {
            color: var(--red-text);
            margin-left: 3px;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
  .input-item {
    display: flex;
    flex-direction: column;
    width: 560px;
    :deep(.ant-popover) {
        width: 500px;
        .tooltip-text {
          padding: 16px;
        }
      }
    .text {
      color: var(--dark-2);
      font-weight: 500;
      line-height: 18px;
      font-size: 13px;
      margin-bottom: 4px;
      display: flex;
      
      > span {
        color: var(--red-text);
        margin-left: 3px;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
  .item-commission {
    display: flex;
    margin-top: 24px;
    > span {
      align-self: center;
      margin: 20px 10px 0 10px;
      font-family: Sora, sans-serif;
      font-weight: 500;
      color: #0f0629;
    }
    .fees,
    .total,
    .commission-input {
      display: flex;
      flex-direction: column;
      > span {
        margin-bottom: 6px;
        font-weight: 500;
        color: var(--dark-2);
        line-height: 19px;
        font-size: 13px;
      }
    }
    .commission-input {
      position: relative;
      .defalut-text {
        position: absolute;
        font-family: Sora, sans-serif;
        color: var(--dark-4);
        line-height: 20px;
        left: 15px;
        top: 34px;
        margin: 0;
      }
      .haifenhao {
        position: absolute;
        font-weight: 500;
        font-size: var(--font-size-large);
        color: var(--dark-1);
        right: 30px;
        top: 34px;
        margin: 0;
      }
      :deep(.ant-input-number) {
        width: 200px;
        .ant-input-number-handler-wrap {
          opacity: 1;
        }
      }
    }
    .total {
      > button {
        width: 96px;
        display: flex;
        justify-content: space-between;
        > span {
          align-self: center;
        }
      }
    }
    .fees {
      > button {
        width: 144px;
      }
      .question-icon {
        margin-left: 3px;
        color: var(--yellow-4);
      }
    }
    .fees,
    .total {
      > button {
        padding-left: 12px;
        text-align: left;
        border-radius: 8px;
        font-weight: 500;
        font-size: var(--font-size-large);
        color: var(--dark-1);
      }
    }
  }
}
.ruleModal-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .operation-btn {
    display: flex;
    font-weight: 400;
    font-size: 13px;
    color: var(--red-text);
    letter-spacing: 0.3px;
    line-height: 20px;
    > span {
      display: flex;
      align-self: center;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        align-self: center;
      }
    }
  }
}
</style>
