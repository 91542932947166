<template>
  <div class="active" v-if="status === 'Active'" :style="noBorder?style:''">
      <div class="circle"><div></div></div>
      <div>Active</div>
  </div>
  <div v-else-if="status === 'Expired'" class="expired" :style="noBorder?style:''" >
      <div class="circle"><div></div></div>
      <div>Expired</div>
  </div>
  <div v-else-if="status === 'Scheduled'" class="scheduled" :style="noBorder?style:''" >
      <div class="circle"><div></div></div>
      <div>Scheduled</div>
  </div>
</template>
<script setup>
  defineProps(['status','noBorder'])
  const style={
    border:'unset',
    background:'transparent',
    'box-shadow':'unset'
  }
</script>
<style lang="less" scoped>
  @flex:{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @font:{ 
    // opacity: 0.7;
    font-family: Sora, sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
    border-radius: 4px
  };
  
.scheduled {
  @font();
  width: 109px;
  display: flex;
  align-items: center;
  padding:0 5px;
  color: rgba(230, 130, 0, 1);
  background: rgba(245, 155, 0, 0.1);
  border: 1px solid rgba(245, 155, 0, 0.4);
  box-shadow: 0 1px 0 0 rgba(26, 22, 37, 0.05);
  .circle{
    @flex();
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: var(--yellow-5);
    border-radius: 50%; 
    >div{
      width: 6px;
      height: 6px;
      background-color: #F59B00;
      border-radius: 50%;
      
    }
  }
}
.expired{
  @font();
  width: 89px;
  display: flex;
  align-items: center;
  padding:0 5px;
  color: #878394;
  background: #EBE9F0;
  border: 1px solid var(--border-1);
  box-shadow: 0 1px 0 0 var(--box-shadow-1);
  .circle{
    @flex();
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: #DFDCE5;
    border-radius: 50%; 
    >div{
      width: 6px;
      height: 6px;
      background-color: #ABA8B4;
      border-radius: 50%;
      
    }
  }
}
.active{
  @font();
  width: 77px;
  display: flex;
  align-items: center;
  padding:0 5px;
  color: #009947;
  background: #2aae671a;
  border: 1px solid #2aae6766;
  box-shadow: 0 1px 0 0 #1a16250d;
  .circle{
    @flex();
    margin-right: 4px;
    width: 12px;
    height: 12px;
    background: #C2E7D4;
    border-radius: 50%; 
    >div{
      width: 6px;
      height: 6px;
      background-color:#2AAE67;
      border-radius: 50%;
      
    }
  }
}
</style>