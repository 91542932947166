<template>
  <div class="rule">
    <div class="cont">
      <div class="item" :class="item.status === 'Expired' ? 'expired-item' : ''" v-for="item in props.list" :key="item.id">
        <div class="head">
          <p>{{item.ruleName}}</p>
          <artmisads-select-button v-if="item.status !== 'Expired'" @click="() => setShowModal(item)" size="middle" :showEditIcon="true">Edit</artmisads-select-button>
        </div>
        <div class="item-main">
          <div class="text">
            <div class="text-item">
              <span class="name">Rule Name:</span>
              <span class="value">{{item.ruleName}}</span>
            </div>
            <div class="text-item">
              <span class="name">Commission Rate:</span>
              <span class="value">{{item.commissionRate}}%</span>
            </div>
          </div>
          <div class="text">
            <div class="text-item">
              <span class="name">Date Range:</span>
              <span class="value">
                {{moment(item.startDate).tz(timeZone).format('YYYY.MM.DD')}} - {{moment(item.endDate).tz(timeZone).format('YYYY.MM.DD')}}
                <span class="expired-time" v-if="item.status === 'Active' && item.expiredDate">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-a-warring-tianchong3"></use>
                  </svg>
                  Expire at {{moment(item.expiredDate).tz(timeZone).format('MM-DD HH:mm:ss')}}
                </span>
              </span>
            </div>
            <div class="text-item">
              <span class="name">Rate Type:</span>
              <span class="value">{{COMMISSION_RULE_TYPE_MAP[item.commissionType]}}</span>
            </div>
            <div class="text-item text-status">
              <span class="name">Status:</span>
              <!-- <status :status="'Scheduled'"/> -->
              <status :status="item.status"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <editRuleModal
      :modalData="modalData"
      :setShowModal="setShowModal"
      :defaultCommission="defaultCommission"
      v-if="showModal"
    />
  </div>
</template>
<script setup>
  import moment from 'moment';
  import { ref, computed } from 'vue';
  import editRuleModal from './editRuleModal.vue';
  import { COMMISSION_RULE_TYPE_MAP } from '~/contants/setting'
  import { COUNTRY_TIME_TZ } from '~/contants/common'
  import status from './status.vue';
  
  import { useUserInfoStore } from "@/storeData";

  const props = defineProps(['list', 'getData', 'defaultCommission'])
  const user = useUserInfoStore();

  const timeZone = computed(() => {
    const countryCode = user?.userInfo?.countryCode || 'US';
    const data = COUNTRY_TIME_TZ[countryCode];
    return data;
  })

  const showModal = ref(false);
  const modalData = ref({});

  const setShowModal = (data, reload) => {
    if (data) {
      modalData.value = data;
      showModal.value = true;
    } else {
      modalData.value = {};
      showModal.value = false;
    }
    if (reload) {
      props.getData()
    }
  }

</script>
<style lang="less" scoped>
  .rule {
    padding: 0 0 24px 0;
    overflow: hidden;
    .item {
      width: 100%;
      border-radius: 8px;
      padding-left: 16px;
      padding-bottom: 24px;
      background-color: var(--color-white);
      box-shadow: var(--section-box-shadow-5);
      margin-top: 16px;
      &.expired-item * {
        color: var(--dark-3) !important;
      }
      .head {
        padding: 16px 16px 16px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--border-4);
        > p {
          font-weight: 600;
          font-size: 20px;
          color: var(--dark-1);
          letter-spacing: 0.5px;
          line-height: 28px;
        }
      }
    }
    .item-main {
      display: flex;
      flex-direction: column;
      .text {
        display: flex;
        width: 100%;
        margin-top: 24px;
      }
      .text-status {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .name {
          margin-bottom: 4px;
        }
      }
    }
    .text-item {
      display: flex;
      flex-direction: column;
      width: 45%;
      padding-right: 30px;
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      .name {
        font-weight: 400;
        font-size: 13px;
        color: var(--dark-3);
        letter-spacing: 0.3px;
        line-height: 18px;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 20px;
        margin-top: 4px;
        display: flex;
        .expired-time {
          margin-left: 12px;
          align-self: center;
          padding: 2px 6px 2px 4px;
          border-radius: 4px;
          font-weight: 500;
          font-size: 12px;
          color: var(--dark-2);
          letter-spacing: 0.3px;
          line-height: 16px;
          background-color: var(  --bg-gray-8);
          svg {
            fill: var(--yellow-7);
          }
        }
      }
    }
  }
</style>
